import debounce from 'lodash/debounce';

const timelineWrapper = document.querySelector('.js-timeline');
const timeline = document.querySelector('.js-tl');

const classes = new Map()
  .set('contentAbove', 'timeline--above__content')
  .set('contentBelow', 'timeline--below__content')
  .set('itemActive', 'tl-item--active')
  .set('itemLink', 'js-tl--link')
  .set('tlNav', 'timeline--nav__link');

// decode HTML characters and parse JSON
const decodeResponse = (html) => {
  const txt = document.createElement('textarea');

  txt.innerHTML = html;
  return JSON.parse(txt.value);
};

// create div including classes and content
const createDiv = (classa, classb, content, year) => {
  const div = document.createElement('div');

  div.classList.add(classa);
  div.classList.add(`${classa}--${classb}`);
  div.dataset.year = year;
  div.innerHTML = content;

  return div;
};

// get siblings of an element
const getSiblingsByDirection = (...args) => {
  let el = args[0];
  const direction = args[1] || 'prev';
  const matchByTag = args[2] || 'li';
  const siblings = [];

  while (direction === 'prev' ? el.previousSibling : el.nextSibling) {
    const sib = direction === 'prev' ? el.previousSibling : el.nextSibling;

    if (sib.tagName && sib.tagName.toLowerCase() === matchByTag) {
      siblings.push(sib);
    }
    el = sib;
  }

  return siblings;
};

// get timeline content items
const getItems = (url) => new Promise((resolve, reject) => {
  const req = new XMLHttpRequest();

  req.open('GET', url);
  req.onload = () => {
    if (req.status === 200) {
      resolve(req.response);
    } else {
      reject(Error(req.statusText));
    }
  };

  // handle network errors
  req.onerror = () => {
    reject(Error('Network Error'));

  };

  // make the request
  req.send();
});

// add timeline content items
const addItems = (response) => {
  const items = decodeResponse(response);
  const itemYear = timeline.querySelector(`.${classes.get('itemActive')}`).dataset.year;
  const sectionAbove = timelineWrapper.querySelector('.timeline--above');
  const sectionBelow = timelineWrapper.querySelector('.timeline--below');
  let currentAbove = timelineWrapper.querySelector(`.${classes.get('contentAbove')}`);
  let currentBelow = timelineWrapper.querySelector(`.${classes.get('contentBelow')}`);

  for (const year in items) {
    const contents = items[year].contents;

    if (year < itemYear) {
      sectionAbove.insertBefore(
        createDiv(classes.get('contentAbove'), 'prev', contents, year),
        currentAbove
      );

      sectionBelow.insertBefore(
        createDiv(classes.get('contentBelow'), 'prev', contents, year),
        currentBelow
      );
    } else if (year > itemYear) {
      const divAbove = createDiv(classes.get('contentAbove'), 'next', contents, year);
      const divBelow = createDiv(classes.get('contentBelow'), 'next', contents, year);

      sectionAbove.insertBefore(
        divAbove,
        currentAbove.nextSibling
      );

      sectionBelow.insertBefore(
        divBelow,
        currentBelow.nextSibling
      );

      currentAbove = divAbove;
      currentBelow = divBelow;
    }
  }
};

// update timeline navigation prev next links
const updatePrevNextLinks = (activeParent) => {
  [ 'prev', 'next' ].forEach((link) => {
    const item = timelineWrapper.querySelector(`.${classes.get('tlNav')}--${link}`);
    const sib = link === 'prev' ? activeParent.previousSibling : activeParent.nextSibling;
    const order = link === 'prev' ? 'first' : 'last';
    const target = sib.tagName ? sib.querySelector('a') : timeline.querySelector(`li:${order}-child a`);

    item.dataset.year = target.dataset.year;
    item.href = target.href;
  });
};

// slide below and above content depending on clicked link
const slideContentAboveAndBelow = ({ toYear = 0, directionInverse = 'next', toDirection = 'prev' } = {}) => {
  [ 'Above', 'Below' ].forEach((pos) => {
    const cl = `content${pos}`;
    const current = timelineWrapper.querySelector(`.${classes.get(cl)}[data-year="${toYear}"]`);
    const liSiblingsAbove = getSiblingsByDirection(current, directionInverse, 'div');

    liSiblingsAbove.forEach((sibling) => {
      if (sibling.tagName) {
        sibling.classList.add(`${classes.get(cl)}--${directionInverse}`);

        if (sibling.classList.contains(`${classes.get(cl)}--${toDirection}`)) {
          sibling.classList.remove(`${classes.get(cl)}--${toDirection}`);
        }
      }
    });

    if (current.classList.contains(`${classes.get(cl)}--prev`)) {
      current.classList.remove(`${classes.get(cl)}--prev`);
    } else if (current.classList.contains(`${classes.get(cl)}--next`)) {
      current.classList.remove(`${classes.get(cl)}--next`);
    }
  });
};

export default () => {
  if (timeline) {
    const location = window.history.location || window.location;
    const itemWrapper = timeline.firstElementChild;
    let itemActive = timeline.querySelector(`.${classes.get('itemActive')}`);

    // add current item to history
    history.pushState({ year: itemActive.dataset.year }, itemActive.dataset.year, itemActive.href);

    // add offset left for timeline nav
    const addOffset = () => new Promise((resolve) => {
      const outerWidth = timeline.offsetWidth;
      const itemNr = itemActive.dataset.number;
      const itemWidth = itemActive.parentNode.offsetWidth; // @todo: parent!
      const offsetLeft = outerWidth / 2 - itemWidth * (parseInt(itemNr, 10) + 0.5);

      itemWrapper.setAttribute('style', `transform: translateX(${offsetLeft}px)`);
      resolve();
    });

    const slideToTimelineItem = (toYear, ...args) => {
      let target = args[0] || null;
      let toDirection = 'next';
      let directionInverse = 'prev';

      // compare toYear and currentYear
      if (itemActive.dataset.year > toYear) {
        toDirection = 'prev';
        directionInverse = 'next';
      }

      // if target is prev / next link, change to real nav target
      if (!target || target.parentNode.tagName.toLowerCase() !== 'li') {
        target = timeline.querySelector(`.${classes.get('itemLink')}[data-year="${toYear}"]`);
      }

      // timeline navigation toggle activeClass and update offset left
      itemActive.classList.remove(classes.get('itemActive'));
      target.classList.add(classes.get('itemActive'));
      itemActive = target;
      addOffset();

      // timeline navigation update prev / next links
      updatePrevNextLinks(itemActive.parentNode);

      // change content above
      slideContentAboveAndBelow({ toYear, directionInverse, toDirection });
    };

    // center timeline initially
    addOffset().then(() => {
      // recalculate center on window resize
      window.addEventListener('resize', debounce(() => {
        addOffset();
      }, 50, this));
    });

    // get timeline items
    getItems(timeline.dataset.url).then((response) => {
      addItems(response);
    }).then(() => {
      // click events timeline navigation
      [ ...timelineWrapper.querySelectorAll(`.${classes.get('itemLink')}`) ].forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();

          let target = e.target;

          // get link as target (not svg / span.. inside link element)
          while (!target.classList.contains(classes.get('itemLink'))) {
            target = target.parentNode;
          }

          const toYear = target.dataset.year;

          slideToTimelineItem(toYear, target);

          // update url
          if (target.href !== location.href) {
            history.pushState({ year: toYear }, toYear, target.href);
          }
        });
      });
    });

    // on back/forward in the browser, move to corresponding item
    window.addEventListener('popstate', (e) => {
      if (e.state && e.state.hasOwnProperty('year')) {
        slideToTimelineItem(e.state.year);
      }
    });
  }
};
