const activeToggleClass = 'toggle--active';
const activeClass = 'button--nav--active';
const activeClassHeader = 'navigation--active';
const noScrollClass = 'no-scroll';
const toggler = document.querySelector('.js-button-nav');
const headerNav = document.querySelector('.navigation');
const toggle = document.querySelector('.toggle');

export default () => {
  if (toggler.classList.contains(activeClass)) {
    toggler.classList.remove(activeClass);
    headerNav.classList.remove(activeClassHeader);
    toggle.classList.remove(activeToggleClass);
    document.documentElement.classList.remove(noScrollClass);
  } else {
    toggler.classList.add(activeClass);
    headerNav.classList.add(activeClassHeader);
    toggle.classList.add(activeToggleClass);
    document.documentElement.classList.add(noScrollClass);
  }
};
