import menutoggle from './menutoggle';
import cookie from './cookie';

const noScrollClass = 'no-scroll';
const closeWelcome = document.getElementById('close-welcome');
const cite = document.getElementById('js-cite');

const welcomeDone = (animationTime = 800) => {
  window.setTimeout(() => {
    document.querySelector('.js-welcome').classList.add('welcome--done');
    document.documentElement.classList.remove(noScrollClass);
  }, animationTime);
};

const startCloseWelcome = (animationTime = 800) => {
  closeWelcome.setAttribute('checked', 'checked');
  welcomeDone(animationTime);
};

export default () => {
  const isHomepage = document.body.classList.contains('page--home');
  const isCentaurPage = document.body.classList.contains('page--centaur');
  const isExtraPage = document.body.classList.contains('page--extra');
  const alreadyVisited = cookie('visited');

  if (isHomepage) {
    // only show splash screen on first visit
    if (alreadyVisited) {
      document.body.classList.add('page--no-welcome');
      startCloseWelcome(0);
    } else {
      const citesObj = JSON.parse(cite.dataset.cites);
      const citesArray = Object.keys(citesObj).map((key) => citesObj[key]);
      const rand = citesArray[Math.floor(Math.random() * citesArray.length)];

      cite.textContent = rand;
      cite.classList.add('cite--show');

      document.documentElement.classList.add(noScrollClass);
    }
  }

  document.onkeydown = (e) => {
    const isMenuVisible = document.querySelector('.js-button-nav').classList.contains('button--nav--active');
    const event = e || window.event;

    // set the event to the esc-key to dismiss splash intro
    if (event.keyCode === 27 && isHomepage && closeWelcome) {
      startCloseWelcome(0);
    }

    // set the event to the esc-key to dismiss the menu
    if (event.keyCode === 27 && isMenuVisible && menutoggle) {
      menutoggle();
    }

    // set the event to the circumflex-key to display the menu
    if (event.keyCode === 192 && !isMenuVisible && menutoggle) {
      menutoggle();
    }

    // set the event to the < -key to get the previous poem
    if (event.keyCode === 37 && (isCentaurPage || isExtraPage)) {
      const previous = document.querySelector('.centaur__content').dataset.previous;

      // don't redirect first poem to root page
      if (previous !== '/') {
        window.location.href = previous;
      }
    }

    // set the event to the > -key to get the next poem
    if (event.keyCode === 39 && (isCentaurPage || isExtraPage)) {
      const next = document.querySelector('.centaur__content').dataset.next;

      // don't redirect first poem to root page
      if (next !== '/') {
        window.location.href = next;
      }
    }
  };

  // remove the transition after it changed
  if (closeWelcome) {
    closeWelcome.addEventListener('change', welcomeDone);
    closeWelcome.addEventListener('click', welcomeDone);
  }
};
