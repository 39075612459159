import debounce from 'lodash/debounce';

const galleryWrapper = document.querySelector('.js-blog-item__gallery');
const showMq = [ 'l', 'xl', 'xxl', 'xxxl' ];

// get horizontal margin(s)
const getMarginHorizontal = (item) => {
  const itemStyle = item.currentStyle || window.getComputedStyle(item);

  return parseInt(itemStyle.marginLeft, 10) + parseInt(itemStyle.marginRight, 10);
};

export default () => {
  if (galleryWrapper) {
    const gallery = galleryWrapper.querySelector('.blog-item__gallery');
    const navLinks = galleryWrapper.querySelectorAll('.blog__gallery-nav');
    const galleryInnermost = gallery.querySelector('.blog__gallery');
    const numberOfItems = parseInt(galleryInnermost.dataset.numberofitems, 10);
    const items = gallery.querySelectorAll('figure');
    const displayType = document.querySelector('.display-type');
    let galleryWidth = gallery.offsetWidth;
    let itemWidth = Math.floor((galleryWidth - getMarginHorizontal(gallery)) / 3);
    let fullItemWidth = Math.floor(getMarginHorizontal(gallery.querySelector('figure')) + itemWidth);
    let maxTranslate = (numberOfItems - 3) * fullItemWidth * -1;
    let mq = window.getComputedStyle(displayType, ':before').content.replace(/("|\')/g, '');

    // recalculate widths
    const recalculate = () => {
      galleryWidth = gallery.offsetWidth;
      itemWidth = Math.floor((galleryWidth - getMarginHorizontal(gallery)) / 3);
      fullItemWidth = Math.floor(getMarginHorizontal(gallery.querySelector('figure')) + itemWidth);
      maxTranslate = (numberOfItems - 3) * fullItemWidth * -1;
      mq = window.getComputedStyle(displayType, ':before').content.replace(/("|\')/g, '');

      // slide to first image
      galleryInnermost.dataset.translate = 0;
      galleryInnermost.style.transform = 'translateX(0px)';
    };

    // add prev and next link navigation
    const addNavEvent = (e) => {
      e.preventDefault();
      let target = e.target;
      let translateVal = parseInt(galleryInnermost.dataset.translate, 10) || 0;

      // get link as target (not svg / span.. inside link element)
      while (target.tagName && target.tagName.toLowerCase() !== 'a') {
        target = target.parentNode;
      }

      // check whether there is an image before / after
      if (target.classList.contains('blog__gallery-nav--next')) {
        translateVal -= fullItemWidth;
      } else {
        translateVal += fullItemWidth;
      }

      if (translateVal <= 0 && translateVal >= maxTranslate) {
        galleryInnermost.dataset.translate = translateVal;
        galleryInnermost.style.transform = `translateX(${translateVal}px)`;
      }
    };

    // adapt widths and add navigation click events
    const execute = () => {
      [ ...items ].forEach((item) => {
        item.style.width = `${itemWidth}px`;
      });

      [ ...navLinks ].forEach((link) => {
        link.addEventListener('click', addNavEvent, false);
      });
    };

    galleryWrapper.classList.add('blog-item__gallery--slide');

    // enable gallery for viewport greater than "m"
    if (showMq.indexOf(mq) >= 0) {
      // enable gallery
      execute();
    }

    // on window resize, recalculate widths
    window.addEventListener('resize', debounce(() => {
      [ ...navLinks ].forEach((link) => {
        link.removeEventListener('click', addNavEvent, false);
      });

      recalculate();

      if (showMq.indexOf(mq) >= 0) {
        execute();
      } else {
        // reset item widths
        [ ...items ].forEach((item) => {
          item.style.width = '';
        });
      }

    }, 50, this));
  }
};
