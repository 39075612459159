const section = document.querySelector('.footnotes');
const list = document.querySelector('.footnotes-list');
const header = document.querySelector('.footnotes-header');
const sectionUp = 'footnotes--up';
const listUp = 'footnotes-list--up';
const links = document.querySelectorAll('.centaur__content a:not(.footnote-backref)');

const slideUp = () => {
  section.classList.add(sectionUp);
  list.classList.add(listUp);
};

const slideDown = () => {
  section.classList.remove(sectionUp);
  list.classList.remove(listUp);
};

export default () => {
  if (section) {
    slideUp();

    header.addEventListener('click', () => {
      if (section.classList.contains(sectionUp)) {
        slideDown();
      } else {
        slideUp();
      }
    });

    [ ...links ].forEach((l) => {
      l.addEventListener('click', () => {
        if (section.classList.contains(sectionUp)) {
          slideDown();
        }
      });
    });
  }
};
