const addCookie = (name, value, days) => {
  const date = new Date();

  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toGMTString()}; path=/`;
};

const readCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export default (name, value = 1, days = 3, action = 'add') => {
  const cookie = readCookie(name);

  if (action === 'add') {
    addCookie(name, value, days);
  }

  return cookie;
};
