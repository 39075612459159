import menutoggle from './menutoggle';

const toggler = document.querySelector('.js-button-nav');

export default () => {
  toggler.addEventListener('click', (e) => {
    e.preventDefault();
    menutoggle();
  });
};
