import cookie from './cookie';

const wideClass = 'wrapper--centaur--wide';
const wideNavClass = 'wrapper--button--nav--wide';
const toggler = document.querySelector('.js-centaur__toggle-formatting');
const footer = document.querySelector('footer');
const toggleMenuBtn = document.querySelector('.wrapper--button--nav');

const wideModus = () => {
  footer.classList.add(wideClass);
  toggleMenuBtn.classList.add(wideNavClass);
};

export default () => {
  const isCentaurPage = document.body.classList.contains('page--centaur');
  const isExtraPage = document.body.classList.contains('page--extra');
  const showOriginalFormatting = cookie('formatting', null, null, 'read');

  if (isCentaurPage || isExtraPage) {
    // activate checkbox if set
    if (showOriginalFormatting === '1') {
      toggler.checked = true;
      wideModus();
    }

    // add footer class to assign min-width
    toggler.addEventListener('change', (e) => {
      if (e.target.checked) {
        wideModus();
        cookie('formatting', 1);
      } else {
        footer.classList.remove(wideClass);
        toggleMenuBtn.classList.remove(wideNavClass);
        cookie('formatting', 0);
      }
    });

    // add footnotes label
    const footnotes = document.querySelector('.footnotes-header');
    const footnote = document.querySelector('.centaur__content').dataset.footnote;

    if (footnotes && footnote) {
      footnotes.innerHTML += footnote;
    }
  }
};
