const activeClass = 'centaur--active';
const wrapper = document.querySelector('.js-centaur');
const toggler = document.querySelectorAll('.js-centaur-toggle');

const toggleActive = (e) => {
  const toggleParent = e.target.parentElement.parentElement;

  // toggle active language
  if (!toggleParent.classList.contains(activeClass)) {
    document.getElementsByClassName(activeClass)[0].classList.remove(activeClass);
    toggleParent.classList.add(activeClass);
  }
};

export default () => {
  const isCentaurPage = document.body.classList.contains('page--centaur-overview');
  const isExtraPage = document.body.classList.contains('page--extra-overview');

  // add classes to display toggles
  if (isCentaurPage || isExtraPage) {
    wrapper.classList.add('centaur--switch');

    // add toggle click event to switch language
    [ ...toggler ].forEach((t) => {
      t.addEventListener('click', (e) => {
        e.preventDefault();
        toggleActive(e);
      });
    });
  }
};
