import viewportfill from 'viewport-units-buggyfill';
import nav from './modules/nav';
import welcome from './modules/welcome';
import centaurOverview from './modules/centaur-overview';
import centaur from './modules/centaur';
import footnotes from './modules/footnotes';
import timeline from './modules/timeline';
import gallery from './modules/gallery';

// import any polyfills and other libs you want to use in older browsers here
import 'svgxuse';

// rollup will wrap all of this in an iife, so document ready is not needed
// lets check if we have a modern browser, and then, enhance!
// if ('visibilityState' in document) {
document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    // remove the no-js class
    document.documentElement.classList.remove('no-js');

    viewportfill.init();
    nav();
    welcome();
    centaurOverview();
    centaur();
    footnotes();
    timeline();
    gallery();
  }
};
